<template>
    <div>
        <the-app-bar/>
        <invoice-details/>
        <Footer/>
    </div>
</template>

<script>

import Footer from '../../components/footer.vue'
import InvoiceDetails from '../../components/MainView/InvoiceDetails.vue'
import TheAppBar from '../../components/NavBar/TheAppBar.vue'
    export default {
  components: { TheAppBar,  Footer, InvoiceDetails },
        
    }
</script>

<style lang="scss" scoped>

</style>