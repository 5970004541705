<template>
  <div class="container ">
    <div class="row  justify-center">
      <div class="col-md-8">
        <section class="card my-5  p-3">
          <div class="card-body" v-if="getInvoiceDetails">
            <!-- Invoice Company Details -->
            <div id="invoice-company-details" class="row">
              <div class="col-md-12">
                <h4 class="text-center">Invoice Details</h4>
                <hr />
              </div>
              <div class="col-md-9 "></div>
              <div class="col-md-3  ">
                <h6>Invoice Number</h6>
                <p>{{ getInvoiceDetails.invoiceNumber }}</p>
              </div>
              <hr />
              <div class="col-md-6 col-sm-12 invoice-bg text-center text-md-left">
                <div class="media">
                  <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

                  <div class="media-body ">
                    <ul class="ml-2 px-0 list-unstyled">
                      <li class="text-bold-800">
                        {{ getInvoiceDetails.clientFirstName }}
                        {{ getInvoiceDetails.clientLastName }}
                      </li>
                      <li>{{ getInvoiceDetails.clientEmail }}</li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 invoice-bg text-center text-md-right">
                <h2></h2>
                <p class="pb-3">{{ getInvoiceDetails.merchantPhoneNumber }}</p>
                <ul class="px-0 list-unstyled">
                  <li>Due Date</li>
                  <li class="lead text-bold-200">
                    {{ getInvoiceDetails.serviceEndDate | formatDate() }}
                  </li>
                </ul>
              </div>
            </div>

            <div id="invoice-company-details" class="row">
              <div class="col-md-12 mt-3">
                <h6>Billing Details</h6>
                <hr />
              </div>
              <div class="col-md-6 col-sm-12 invoice-bg text-center text-md-left">
                <div class="media">
                  <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

                  <div class="media-body ">
                    <ul class="m-2 px-0 list-unstyled">
                      <li class="text-bold-800">Amount</li>
                      <li> {{ getInvoiceDetails.benefactorCountryCurrency }} {{ getInvoiceDetails.cost }}</li>
                      <hr />
                      <li>Total amount paid</li>
                      <li>
                        {{ getInvoiceDetails.benefactorCountryCurrency }}
                        {{ getInvoiceDetails.totalPaid }}
                      </li>
                      <hr />
                      <li>Due Date</li>
                      <li class="lead text-bold-600">
                        {{ getInvoiceDetails.dueDate }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 invoice-bg text-center text-md-right">
                <h6 class="m-2">Charges</h6>
                <p class="pb-3 m-2">
                  {{ getInvoiceDetails.benefactorCountryCurrency }}
                  {{ getInvoiceDetails.serviceCharge }}
                </p>
              </div>
            </div>

            <div id="invoice-company-details" class="row">
              <div class="col-md-12 mt-3">
                <h6>Service Details</h6>
                <hr />
              </div>
              <div class="col-md-12 col-sm-12 invoice-bg text-center text-md-left">
                <div class="media">
                  <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

                  <div class="media-body ">
                    <ul class="m-2 px-0 list-unstyled">
                      <li><b>Service Provider</b></li>
                      <li>{{ getInvoiceDetails.merchantBusinessName }}</li>
                      <li>
                        Location:
                        {{ getInvoiceDetails.merchantBusinessAddress }}
                      </li>
                      <hr />
                      <li><b>Services Offered</b></li>
                      <li>{{ getInvoiceDetails.serviceOfferedText }}</li>
                      <hr />
                      <li><b>Service Duration</b></li>
                      <li class="lead text-bold-400">
                        {{ getInvoiceDetails.serviceStartDate | formatDate() }}
                        - {{ getInvoiceDetails.serviceEndDate | formatDate() }}
                      </li>

                      <hr />
                      <li class="text-bold-800"><b>Total Balance</b></li>
                      <li class="text-bold-800 text-2xl"> {{ getInvoiceDetails.benefactorCountryCurrency }}
                        <b> {{ getInvoiceDetails.balance }}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div
                class="col-md-6 col-sm-12 invoice-bg text-center text-md-right"
              >
                <h6 class="m-2">
                  {{ getInvoiceDetails.createdDate | formatDate() }}
                </h6>
              </div> -->
            </div>

            <div id="invoice-company-details" class="row">
              <div class="col-md-12 mt-3">
                <h6>Documents</h6>
                <hr />
              </div>
              <div class="col-md-6 col-sm-12 invoice-bg text-center text-md-left">
                <div class="media">
                  <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

                  <div v-if="getInvoiceDetails.documents" class="media-body ">
                    <ul v-for="doc in getInvoiceDetails.documents" :key="doc.index" class="m-2 px-0 list-unstyled">
                      <span class="text-oonpaysecondary"><a target="_blank" :href="
                            `${ImagebaseUrl}/oonpayinvoices/${encodeURIComponent(
                              doc.docUrl
                            )}`
                          "><i class="fa fa-eye" aria-hidden="true"></i>
                          {{ doc.fullName }}</a></span>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 invoice-bg text-center text-md-right">
                <h6 class="m-2"></h6>
              </div>
            </div>

            <div class="row m-5">

              <div v-if="countryISOCode == 'USD'" class="col-md-12">
                <button type="button" class="btn btn-block btn-info" data-toggle="modal"
                  data-target="#makePaymentModal">
                  Make Payment
                </button>
              </div>
              <div v-if="countryISOCode == 'GHS' || countryISOCode == 'NG'" class="col-md-12">
                <button type="button" class="btn btn-block btn-success" data-toggle="modal"
                  data-target="#PayStackModal">
                  Make Payment
                </button>
              </div>

              <div class="modal fade" id="makePaymentModal" tabindex="-1" role="dialog"
                aria-labelledby="makePaymentModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Payment information
                      </h5>

                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body ">
                      <div v-if="elementsOptions.clientSecret">
                        <stripe-element-payment ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
                          :confirm-params="confirmParams" />
                        <button class="btn btn-primary mt-3 btn-block" @click.prevent="pay">
                          Pay Now
                        </button>
                      </div>
                      <div v-else>
                        <form>
                          <div class="form-group">
                            <label for="amount">Enter Amount</label>
                            <input type="text" class="form-control" placeholder="enter amount" v-model="amount" />
                          </div>
                          <div class="form-group">
                            <label for="amount">Enter Your Email</label>
                            <input type="email" class="form-control" placeholder="enter email"
                              v-model="paymentReceiptEmail" />
                          </div>
                          <button class="btn btn-primary mt-3 btn-block" @click.prevent="generatePaymentIntent">
                            {{ loading == false ? "Next" : "loading..." }}
                          </button>
                        </form>
                      </div>
                    </div>
                    <div class="modal-footer"></div>
                  </div>
                </div>
              </div>

              <!-- Paystack payment -->
              <div class="modal fade" id="PayStackModal" tabindex="-1" role="dialog"
                aria-labelledby="PayStackModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Payment information
                      </h5>

                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body ">
                      <section>
                        <!-- <div class="form-group">
                          <label for="fullname">Full Name</label>
                          <input
                            v-model="full_name"
                            required
                            type="text"
                            class="form-control"
                            placeholder="enter full name"
                            name="uname"
                          />
                        </div> -->

                        <!-- {{countryISOCode}} -->

                        <div class="form-group">
                          <label for="Email Address">Email Address</label>
                          <input v-model="email" required class="form-control" type="text"
                            placeholder="customer@email.com" name="email" />
                        </div>

                        <div class="form-group">
                          <label for="Amount">Amount</label>
                          <input v-model="amount" type="tel" class="form-control" placeholder="1000" name="amount"
                            required />
                        </div>
                      </section>
                      <paystack class="btn btn-danger btn-block" :amount="amount * 100" :email="email"
                        :paystackkey="GH_PUBLIC_KEY" :reference="reference" :callback="processPayment" :close="close"
                        currency="GHS" :channels="channels" v-if="countryISOCode == 'GHS'" :metadata="metadata"
                        :disabled="disabled">
                        Pay
                      </paystack>

                      <paystack class="btn btn-danger btn-block" :amount="amount * 100" :email="email"
                        :paystackkey="NG_PUBLIC_KEY" :reference="reference" :callback="processPayment" :close="close"
                        currency="NGN" :channels="channels" :metadata="metadata" v-if="countryISOCode == 'NGN'">
                        Pay
                      </paystack>
                    </div>
                    <span class="text-danger text-center">{{message}}</span>
                    <div class="modal-footer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import paystack from "vue-paystack";
import { ImagebaseUrl, baseUrl, config } from "../../config";
// import { mapGetters } from 'vuex';

export default {
  components: {
    StripeElementPayment,
    paystack,
  },
  data() {
    return {
      pk: config.PK,
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: "",
      },
      ImagebaseUrl,
      getInvoiceDetails: {},
      NG_PUBLIC_KEY: config.NG_PUBLIC_KEY,
      GH_PUBLIC_KEY: config.GH_PUBLIC_KEY,
      metadata: {
        benefactorGUID: "",
        clientGUID:"",
        invoiceGUId: "",
        webKey: config.webKey,
        paymentTypeId: 2,
      },
      disabled: false,
      message:"",
      amount: "",
      full_name: null,
      email: "",
      countryISOCode: null,
      confirmParams: {
        return_url: `${baseUrl}/success`, // success url
      },
      paymentReceiptEmail: "",
      loading: false,
      channels: ["card", "bank", "mobile_money", "bank_transfer"],
    };
  },
  watch:{
    amount: {
    handler:function(newVal) {
      if(newVal > this.getInvoiceDetails.balance){
       this.disabled = true
       this.message = "Amount entered is greater than balance"
      }else{
        this.disabled = false
       this.message = ""
      }
    },
     deep:true
    },
  },
  mounted() {
    if (localStorage.getItem("invoice")) {
      try {
        this.getInvoiceDetails = JSON.parse(localStorage.getItem("invoice"));
        this.countryISOCode = this.getInvoiceDetails.benefactorCountryISOCode;
        this.metadata.benefactorGUID = this.getInvoiceDetails.benefactorGUID;
        this.metadata.clientGUID = this.getInvoiceDetails.benefactorGUID;
        this.metadata.invoiceGUId = this.getInvoiceDetails.guid;
      } catch (e) {
        localStorage.removeItem("invoice");
      }
    }
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    
  },
 

  methods: {
    generatePaymentIntent() {
      const payload = {
        amount: this.amount,
        invoiceGUId: this.getInvoiceDetails.guid,
        benefactorGUID: this.getInvoiceDetails.benefactorGUID,
        paymnetReceiptEmail: this.paymentReceiptEmail,
        paymentTypeId: 1
      };
      this.loading = true;
       
        this.$store
          .dispatch("invoice/getSecurite", payload)
          .then((res) => {
            console.log(res)
            this.elementsOptions.clientSecret = res.data;
            this.loading = false;
          });
     
    },
    pay() {
      this.$refs.paymentRef.submit();
    },

    // Paystack
    processPayment: () => {
      location = "/";
    },
    close: () => {
      console.log("You closed checkout page");
    },
  },
};
</script>

<style scoped>
.invoice-bg {
  background: #f2f2f2;
}

.btn-success {
  background: #f15825 !important;
}
</style>
